<template>
  <figure class="relative shadow-glowWhite shadow-md w-11/12 sm:w-96 m-4">
    <img class="block w-full h-auto object-cover object-center" :src="`${$store.getters.URLSITE}/pictures/guessWhichPhoto.jpg`">
    <template v-if="getUserLogged">
      <template v-if="getDailyGames.guessWinner.settings.active">
          <div v-if="typNacitaniaHry=='play'" class="-translate-x-1/2 absolute bg-purple-600 bottom-1 font-bold left-1/2 px-10 py-1 text-xl transform cursor-pointer" @click="openGameGuessWinner()">PLAY</div>
          <div v-else-if="typNacitaniaHry=='resume'" class="-translate-x-1/2 absolute bg-blue-our bottom-1 font-bold left-1/2 px-10 py-1 text-xl transform cursor-pointer" @click="openGameGuessWinner()">PLAY</div>
          <div v-else-if="typNacitaniaHry=='result'" class="-translate-x-1/2 absolute bg-orange-our bottom-1 font-bold left-1/2 px-10 py-1 text-xl transform cursor-pointer" @click="openGameGuessWinner()">RESULT</div>
      </template>
    </template>
    <template v-else>
      <div class="-translate-x-1/2 absolute bg-purple-600 bottom-1 font-bold left-1/2 px-10 py-1 text-xl transform cursor-pointer" @click="openLogin()">PLAY</div>
    </template>
  </figure>
</template>

<script>
export default {
    computed:{
      getDailyGames(){
        return this.$store.getters.getDailyGames;
      },
      getUserLogged(){
        return this.$store.getters.getUserLogged;
      },
      typNacitaniaHry(){
        if('result' in this.getDailyGames.guessWinner){
          return 'result';
        }else if('photoList' in this.getDailyGames.guessWinner && 'photoPairs' in this.getDailyGames.guessWinner){
          return 'resume';
        }
        return 'play';
      },
    },
    methods:{
      openGameGuessWinner(){
        this.$store.commit('setLowerModal',{visible:true,whatToShow:'GameGuessWinner',data:{}}); 
      },
      openLogin(){
        this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
      },
      openResult(){
        this.$store.commit('setUpperModal',{visible:true,whatToShow:'DailyGamesResults',data:{gameName:'Guess which photo',game:this.getDailyGames.guessWinner}});
      }
    },
}
</script>

import GuessWinner from '../components/Games/GuessWinner.vue';
import GuessBrand from '../components/Games/GuessBrand.vue'; // import Axios from 'axios'

export default {
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');
    this.zistiFirstEverLoad();
  },
  // mixins:[functions],
  components: {
    GuessWinner: GuessWinner,
    GuessBrand: GuessBrand
  },
  computed: {
    getDailyGames: function getDailyGames() {
      return this.$store.getters.getDailyGames;
    }
  },
  methods: {
    zistiFirstEverLoad: function zistiFirstEverLoad() {
      if (!localStorage.getItem('CFdailyGames')) {
        //--------ak otvara okno s tym ze sa otvara este top100 alebo nejake konkretne vysledky nespravi nic
        if (!this.$route.params.action) {
          this.$store.commit('setUpperModal', {
            visible: true,
            whatToShow: 'FirstEverLoadDailyGames',
            data: {}
          });
          localStorage.setItem('CFdailyGames', JSON.stringify(true));
        }
      } else {
        this.$store.commit('setFirstEverLoad', {
          dailyGames: true
        });
      }
    }
  }
};